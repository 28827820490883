import { IonBackButton, IonButtons, IonHeader, IonSpinner, IonToolbar } from "@ionic/react";
import { AddLicenseForm } from "@src/app/licenseManager/addLicense";
import { addLicense, getLicenses } from "@src/app/licenseManager/api";
import { LICENSE_DETAILS_INIT } from "@src/app/licenseManager/constants";
import { ICreateLicense } from "@src/app/licenseManager/interfaces";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { USER_EVENTS } from "@src/constants";
import { addDataToIdentity, logApiFailureEvent } from "@src/lib/analytics";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../style.scss";

import Alert from "./Alert";
import { fireVerifyLicenseZapierHook, updateAgentData } from "./api";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { useSelectedWorkType } from "../hooks/useSelectedWorkType";
import { logOnboardingError } from "../util/logging";

const AgentOnboardingLicense: FC<
  Omit<OnBoardingComponentProps, "nextStagePath"> & {
    nextStagePath: (isPermanentPlacement: boolean) => string;
  }
> = ({ agent, nextStagePath }) => {
  const history = useHistory();
  const { userId } = useDefinedWorker();
  const [addingLicense, setAddingLicense] = useState<boolean>(false);
  const [gettingLicense, setGettingLicense] = useState<boolean>(true);
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });
  const [license, setLicense] = useState<ICreateLicense>(LICENSE_DETAILS_INIT);
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const { showErrorToast } = useToast();
  const updateWorkerCache = useUpdateWorkerCache();
  const { isPermanentPlacementsOnboarding } = useSelectedWorkType();

  const closeErrorModal = () => {
    setErrorAlert({ hasError: false });
  };

  useEffect(() => {
    (async () => {
      try {
        setGettingLicense(true);
        const response = await getLicenses(String(userId));

        if (response && response.length) {
          setDisableForm(true);
          setLicense({
            ...response[0],
          });
        }
      } catch (error) {
        logApiFailureEvent(error);
      } finally {
        setGettingLicense(false);
      }
    })();
  }, [userId]);

  const submitLicense = (license: ICreateLicense) => {
    (async () => {
      setErrorAlert({ hasError: false });
      try {
        if (!agent?.userId) {
          throw new Error("agentId not available");
        }
        if (!disableForm) {
          setAddingLicense(true);

          const agentProfileLicenseDetails = {
            ...license,
            expires: license.expiresAt,
          };

          setDisableForm(true);
          await addLicense(license, agent?.userId);

          logEvent(USER_EVENTS.LICENSE_INFO_ENTERED);
          addDataToIdentity(agent?.userId, {
            selfob_entered_license_information_at: new Date(),
          });
          const updatedAgent = {
            ...agent,
            license: agentProfileLicenseDetails,
            qualification: license.qualification,
          };
          await updateAgentData({
            stage: ONBOARDING_STAGES.LICENSE,
            license: agentProfileLicenseDetails,
            qualification: license.qualification,
            otherQualification: "",
          });

          updateWorkerCache({
            preference: {
              ...agent?.preference,
              qualification: license.qualification,
            },
          });

          fireVerifyLicenseZapierHook(updatedAgent);
        }
        setAddingLicense(false);
        history.push(`${nextStagePath(isPermanentPlacementsOnboarding)}`);
      } catch (error) {
        setAddingLicense(false);
        setDisableForm(false);

        if (!error.response || !error.response.text || error.response.status >= 500) {
          logOnboardingError(
            ONBOARDING_STAGES.QUALIFICATION,
            (error as Error).message,
            agent?.userId
          );
          setErrorAlert({ hasError: true, reason: error?.message });
          return;
        }
        showErrorToast(error.response.text);
      }
    })();
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentSignupInfo2" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <Alert
        isOpen={errorAlert.hasError}
        reason={errorAlert?.reason}
        closeAlert={closeErrorModal}
      />
      {gettingLicense ? (
        <div
          className="ion-justify-content-center ion-align-items-center"
          style={{ display: "flex", height: "100%" }}
          data-testid="onboarding-license-loader"
        >
          <IonSpinner color="dark" />
        </div>
      ) : (
        <AddLicenseForm
          licenseDetails={license}
          submitLicense={submitLicense}
          addingLicense={addingLicense}
          forOnboarding={true}
          disableForm={disableForm}
        />
      )}
    </StyledIonPage>
  );
};

export { AgentOnboardingLicense };
